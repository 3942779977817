
import {Component, Vue} from "vue-property-decorator";
import {ICrudOption} from "@/types/m-ui-crud";
import {getProductDetailApi, modifyProductStatusApi, queryProductListApi} from "@/apis/product";
import {IProduct, IProductQuery} from "@/apis/product/types";
import {Message} from "element-ui";
import config from "@/config";
import router from "@/router";
import {getProductSortListApi} from "@/apis/category";
import {ICategory} from "@/apis/category/types";

@Component({})
export default class ProductPage extends Vue {

  config = config;
  //查询条件
  queryForm: IProductQuery = {
    page: 1,
    limit: 10
  }
  total: number = 0

  //列表数据
  tableData: IProduct[] = []

  // 新增修改表单
  modelForm: IProduct = {}

  //分类下拉数据
  sortTreeList: ICategory[] = [];


  /***
   * crudOption
   * 页面配置对象
   */
  crudOption: ICrudOption = {
    searchBox: true,
    menu: true,
    editBtn: true,
    delBtn: false,
    addTitle: "新增商品",
    editTitle: "编辑商品",
    column: [
      {
        label: "商品名称",
        prop: "productName",
        placeholder: "请输入商品名称",
        align: 'center',
        search: true,
        hide: true,
        addHide: true,
        editHide: true,
        viewHide: true
      },
      {
        label: "商品编号",
        prop: "id",
        align: 'center',
        addHide: true,
        editHide: true,
        // slot: true,
        width: 200,
      },
      {
        label: "商品名称",
        prop: "productName",
        align: 'center',
        maxlength: 20,
        rules: [
          {required: true, message: '请输入商品名称', trigger: 'blur'},
        ],
        span: 12,
      },
      {
        label: "商品分类",
        prop: "sortId",
        addSlot: true,
        editSlot: true,
        searchSlot: true,
        align: 'center',
        hide: true,
        viewHide: true,
        search: true,
        rules: [
          {required: true, message: '请选择分类', trigger: 'blur'},
        ],
        span: 12,
      },
      {
        label: "商品分类",
        prop: "sortName",
        align: 'center',
        addHide: true,
        editHide: true,
      },

      {
        label: "商品图片",
        prop: "productBannerPic",
        type: 'image',
        align: 'center',
        search: false,
        addSlot: true,
        editSlot: true,
        imgPrefix: config.downloadUrl,
        rules: [
          {required: true, message: '请上传商品图片', trigger: 'blur'},
        ],
        span: 24,
      },
      {
        label: "商品售价",
        prop: "productPrice",
        align: 'center',
      },
      {
        label: "销量",
        prop: "salesVolume",
        align: 'center',
      },
      {
        label: "库存",
        prop: "stock",
        align: 'center',
      },
      {
        label: "排序",
        prop: "sort",
        align: 'center',
      },
      {
        label: "商品状态",
        prop: "status",
        align: 'center',
        search: true,
        type: 'switch',
        slot: true,
        dicData: [
          {
            label: "下架",
            value: 2
          },
          {
            label: "上架",
            value: 1
          },
        ],
        addHide: true,
        editHide: true
      },
    ]
  }


  /**
   * 获取列表
   */
  getList() {
    queryProductListApi(this.queryForm).then(e => {
      this.tableData = e.list;
      this.total = e.total
    })
  }

  /**
   * 全部分类
   */
  getSortTreeList() {
    getProductSortListApi().then(e => {
      this.sortTreeList = e.map((item: any) => {
        return {
          ...item,
          subProductSortList: item.subProductSortList || []
        }
      });
    })
  }

  /**
   * 切换状态
   * @param id
   */
  handleSwitch(id: string,status:string) {
    modifyProductStatusApi({id, status}).then(e => {
      if (e) {
        Message.success("操作成功!")
        this.getList()
      }
    })
  }

  /**
   * 上传前检验
   * @param file
   */
  beforeUpload(file: File) {
    if (file.type != "image/png" && file.type != "image/jpg" && file.type != "image/jpeg") {
      Message.error("请选择正确格式")
      return false
    }
  }

  /**
   * 打开详情
   * @param item
   * @param index
   */
  openDetail(item: any, index: number) {
    // getProductDetailApi(item.id).then(e => {
    //   // @ts-ignore
    //   this.$refs.crudRef.rowView(e, index)
    // })
  }

  /**
   * 修改前,添加前
   * @param  item: any
   */
  handleEditOrAdd(item?: any) {
    let url='/productManage/addProduct'
    if(item)
      url=url+`?id=${item.id}`
    router.push({path:url})
  }
  created() {
    this.getList();
    this.getSortTreeList();
  }
}
